import { keyBy } from 'lodash-es';

import ASYNC_DATA_KEYS from '~/constants/async-data-keys';

import { communityService } from '~/services/community';

const useCommunityLastRegDatesOfBoards = (
  options: Partial<{
    lazy: boolean;
    server: boolean;
  }> = {},
) => {
  return useAsyncDataWrapper(
    ASYNC_DATA_KEYS['/v1/user/community/category/board/latest-dates'](),
    async () => {
      const {
        data: { items },
      } = await communityService.getLastRegDatesOfBoards();

      return keyBy(items, 'boardSeq');
    },
    { ...options },
  );
};

export default useCommunityLastRegDatesOfBoards;
