import { safeDateParse } from '~/libs/dateUtils';

const useBoardHasNewContent = async () => {
  const { data: lastRegDatesOfBoards } = await useCommunityLastRegDatesOfBoards({ lazy: true, server: false });
  const lastRegDatesFromLocalStorage = useLocalStorage<Record<number, string>>('wb:community:readBoards', {});

  return computed(() => {
    return (boardSeq: number) => {
      // 게시판 데이터가 아예 없음
      if (!lastRegDatesOfBoards.value) {
        return false;
      }

      const lastRegDate = lastRegDatesOfBoards.value[boardSeq]?.lastRegDate;

      // 해당 게시판 데이터가 없음
      if (!lastRegDate) {
        return false;
      }

      const lastRegDateFromLocalStorage = lastRegDatesFromLocalStorage.value[boardSeq];

      // 로컬스토리지에 해당 게시판 데이터가 없음
      if (lastRegDateFromLocalStorage === undefined) {
        return true;
      }

      return safeDateParse(lastRegDate).isAfter(lastRegDateFromLocalStorage);
    };
  });
};

export default useBoardHasNewContent;
