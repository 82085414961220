import ASYNC_DATA_KEYS from '~/constants/async-data-keys';

import { communityService } from '~/services/community';
import { useUserAuthStore } from '~/services/userAuth';

const useCommunityPersonalBoards = (
  options: Partial<{
    lazy: boolean;
  }> = {},
) => {
  const userAuthStore = useUserAuthStore();

  return useAsyncDataWrapper(
    ASYNC_DATA_KEYS['/v1/user/community/board/personal'](),
    async () => {
      // 비회원은 요청 안함
      if ($isEmpty(userAuthStore.user)) {
        return null;
      }

      const { data } = await communityService.getPersonalBoardList();

      return data?.data?.items;
    },
    // 현재 서버사이드에 사용자 토큰을 받을 수 없어, server 옵션은 false로 고정합니다
    { ...options, server: false, watch: [() => userAuthStore.user] },
  );
};

export default useCommunityPersonalBoards;
